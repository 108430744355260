.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.prepared-edit-mode {
  display: block;
  background: #00ff55;
  border: 5px solid #00cc22;
  position: absolute;
  top: 5px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 10000px;
  z-index: 100000;
}


.react-autosuggest__input {
  /*width: 240px;*/
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  position: relative;
  top: -4px;
  margin-left: 10px;
  width: calc(100vw - 40px);
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  /*display: none;*/
/*}*/
/**/
/*.react-autosuggest__suggestions-container--open {*/
  display: block;
  position: absolute;
  top: 46px;
  width: 336px;
  border: 1px solid #f0f0f0;
  border-top: none;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  margin-left: calc(50% - 326px / 2 - 10px);
  /*margin-right: 50%;*/
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  border-radius: 0 0 10px 10px;
  box-shadow: 6px 5px 5px rgba(0,0,0,0.05), -6px 5px 5px rgba(0,0,0,0.05);
}

@media(min-width: 1024px) {
  .react-autosuggest__suggestions-container {
    margin-left: calc(50% - 326px / 2 - 32px);
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: left;
  line-height: 1.1em;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 10px;
  color: #666;
}

.react-autosuggest__suggestion:nth-child(2n) {
  background: #fafafa;
}

.react-autosuggest__suggestion--highlighted,
.react-autosuggest__suggestion--highlighted:nth-child(2n) {
  background-color: #ffc700;
  /*background-color: #00c7ff22;*/
  /*color: white;*/
}
